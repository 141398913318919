$color-gray-darkest: #0d0d0d
$color-gray-darker: #171717
$color-gray-dark: #323232
$color-gray: #85858a
$color-white: #ffffff
$color-blue: #229bd4

$font-size-larger: 48px
$font-size-large: 24px
$font-size-normal: 16px

$font-weight-light: 300
$font-weight-normal: normal
$font-weight-bold: 500

$spacing-large: 60px
$spacing-normal: 30px
$spacing-small: 15px
$spacing-smaller: 8px
$spacing-smallest: 4px

=small
  @media only screen and (max-width: 767px)
    @content

=medium
  @media only screen and (min-width: 768px)
    @content

*
  box-sizing: border-box

html
  background: $color-gray-darkest
  color: $color-white
  font-family: "Helvetica Neue", "Helvetica", sans-serif
  font-size: $font-size-normal

body
  margin: 0

h2,
p,
li
  line-height: 1.4em

a
  color: $color-blue
  text-decoration: underline

  &:hover,
  &:focus
    text-decoration: none

+medium
  [ui-container]
    max-width: 850px
    margin: 0 auto !important

[ui-hero]
  text-align: center

[ui-hero-text]
  font-size: $font-size-large
  padding: $spacing-large $spacing-normal

  > p
    margin: 0
    margin-bottom: $spacing-smaller

[ui-hero-image]
  display: block
  width: 100%

+medium
  [ui-hero]
    height: 566px
    margin:
      top: $spacing-large
      bottom: $spacing-large
    position: relative
    display: flex
    align-items: center

  [ui-hero-text]
    font-size: $font-size-larger
    font-weight: $font-weight-light
    text-align: left
    padding-left: calc(50% + #{$spacing-small})

  [ui-hero-image]
    position: absolute
    bottom: 0
    right: calc(50% + #{$spacing-small})

[ui-section]
  border-top: 1px solid $color-gray-dark
  padding: $spacing-large $spacing-normal

  h2
    margin-top: 0
    margin-bottom: $spacing-smaller
    font-size: $font-size-large
    font-weight: $font-weight-normal

  p
    margin-top: 0
    margin-bottom: $spacing-small

    &:last-child
      margin-bottom: 0

  blockquote
    margin:
      top: 0
      bottom: $spacing-normal
      left: 0
      right: 0

    &:last-child
      margin-bottom: 0

    > p
      margin-bottom: 0

    > p:before
      content: "“"
      margin-right: 3px

    > p:after
      content: "”"
      margin-left: 3px

[ui-section~="collection"]
  [ui-section-item]
    margin-bottom: $spacing-normal

    &:last-child
      margin-bottom: 0

[ui-section~="split"]
  [ui-section-item]
    margin-bottom: $spacing-normal

    &:last-child
      margin-bottom: 0

  [ui-section-item-block]
    margin-bottom: $spacing-normal

    &:last-child
      margin-bottom: 0

+medium
  [ui-section~="collection"]
    display: flex
    flex-wrap: wrap

    [ui-section-item]
      width: 50%
      margin-bottom: $spacing-normal

      &:nth-child(2n)
        padding-left: $spacing-small

      &:nth-child(2n+1)
        padding-right: $spacing-small

  [ui-section~="split"]
    display: flex
    align-items: center

    [ui-section-item]
      margin-bottom: 0

    [ui-section-item~="left"]
      order: 0
      margin-right: $spacing-normal

    [ui-section-item~="right"]
      order: 1

[ui-image~="over-stretch"]
  width: calc(100% + #{$spacing-normal * 2})
  margin:
    left: -$spacing-normal
    right: -$spacing-normal

[ui-image~="stretch"]
  width: 100%

[ui-image~="center"]
  display: block
  margin:
    left: auto
    right: auto

[ui-image~="rounded"]
  border-radius: 3px

[ui-list]
  > li:not(:last-of-type)
    margin-bottom: $spacing-smallest

[ui-text~="center"]
  text-align: center

[ui-text~="subtle"]
  color: $color-gray

+medium
  [ui-show~="small"]
    display: none !important

+small
  [ui-show~="medium+"]
    display: none !important

@for $i from 1 through 4
  [ui-footnote~="#{$i}"]:after
    color: $color-gray
    content: "#{$i}"
    padding-left: 0.35em
    font-size: 75%
    position: relative
    bottom: 0.35em
